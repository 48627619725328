export const initFbsdk = () => {
    return new Promise(() => {
        window.fbAsyncInit = function() {
            // eslint-disable-next-line no-undef
            FB.init({
                // appId: '163227404233820',
                appId: '768660731424453',
                cookie: true,
                xfbml: true, // parse social plugins on this page
                version: 'v16.0', // use graph api version 2.8
            });
        };
        (function(d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = '//connect.facebook.net/en_US/sdk.js';
            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
    });
};
