<template>
    <product-variant-detail />
</template>

<script>
import ProductVariantDetail from '../../components/products/VariantDetail';

export default {
    components: {
        'product-variant-detail': ProductVariantDetail,
    },
};
</script>
